import { Tabs, Tab, Box, SxProps } from "@mui/material";
import React from "react";
import { theme } from "../../../utils/theme";
import styled from "@emotion/styled";

const StyledTabs = styled(Tabs)({
  backgroundColor: "white",
  "& .MuiTab-root": {
    fontWeight: "200",
    minHeight: "36px !important",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  "& .Mui-selected": {
    backgroundColor: "black",
    color: "inherit",
    textColor: "inherit",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "black",
    textColor: "white",
    color: "white",
  },
  "& .MuiTab-root.Mui-selected": {
    color: "white",
  },
});

const StyledTab = styled(Tab)({
  textTransform: "none",
  ":hover": {
    backgroundColor: "black",
    color: "white",
  },
});

type Props = {
  items: { label: string; key: string, disabled?: boolean }[];
  value: string;
  onChange: (value: string) => void;
  tabStyle?: SxProps
  tabItemStyle?: SxProps;
  tabContainerStyle?: SxProps;
};

export default function TabComp({
  items,
  value,
  onChange,
  tabStyle = {},
  tabContainerStyle = {},
  tabItemStyle = {},
}: Props) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", ...tabContainerStyle }}>
      <StyledTabs
        value={value}
        onChange={(e, val) => onChange(val)}
        sx={tabStyle}
      >
        {items.map((item, index) => (
          <StyledTab
            key={index}
            label={item.label}
            value={item.key}
            sx={tabItemStyle}
            disabled={item.disabled}
          />
        ))}
      </StyledTabs>
    </Box>
  );
}
