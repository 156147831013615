import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import { IconDownload } from "@tabler/icons-react";
import React from "react";
import { theme } from "../../utils/theme";
import ImgMapper from "../../utils/document-img-mapper";
import { CDN_SIGN, CDN_URL } from "../../utils/url";
import { Docs } from "../../utils/types";

type Props = {
  onClose: () => void;
  handleDownload: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    ulr: string,
    documentName: string
  ) => void;
  documents: Docs[];
  selectedDocument?: Docs;
  onChangeSelection: (document: Docs) => void;
};

const DocumentPreview = ({
  documents = [],
  selectedDocument,
  onChangeSelection,
  handleDownload,
  onClose,
}: Props) => {
  const [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    resetComp();
  }, [selectedDocument]);

  // Had to reset the document viewer to properly support the file change else it was not working
  const resetComp = () => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 1);
  };

  if (reset) return null;
  return (
    <Box
      sx={{
        position: !selectedDocument ? "absolute" : "relative",
        height: !selectedDocument ? 0 : "calc(100vh - 300px)",
        width: !selectedDocument ? 0 : "100%",
        "#pdf-controls": { zIndex: 100 },
        "#pdf-download": {
          display: "none",
        },
        "#pdf-zoom-out": {
          display: "none",
        },
        "#pdf-zoom-in": {
          display: "none",
        },
        "#pdf-zoom-reset": {
          display: "none",
        },
        "#proxy-renderer": {
          overflowY: "auto",
          iframe: {
            minHeight: "600px",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "4px",
          height: "100%",
        }}
      >
        {selectedDocument && (
          <Box>
            {documents.map((document, index) => (
              <Card
                sx={{
                  display: "flex",
                  marginTop: "4px",
                  boxShadow: 0,
                  p: 0,
                  border: 1,
                  borderColor:
                    document.documentLink === selectedDocument.documentLink
                      ? "#26890d"
                      : theme.palette.grey[200],
                  cursor: document.documentLink ? "pointer" : "default",
                }}
                onClick={() =>
                  document.documentLink && onChangeSelection?.(document)
                }
              >
                <img
                  src={ImgMapper[document.fileType]}
                  alt={document.heading}
                  height={40}
                  width={50}
                />
                <CardContent
                  sx={{
                    p: 0,
                    pl: 1,
                    width: "100%",
                    boxShadow: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "&:last-child": {
                      paddingBottom: 0,
                    },
                    backgroundColor: !document.documentLink
                      ? theme.palette.grey[100]
                      : undefined,
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}>
                    {document.heading.charAt(0).toUpperCase() +
                      document.heading.slice(1)}
                  </Typography>
                  {document.documentLink && (
                    <IconButton
                      onClick={(event) =>
                        document.documentLink &&
                        handleDownload?.(
                          event,
                          document.documentLink,
                          document.heading
                        )
                      }
                      sx={{
                        ":hover": {
                          color: "#26890d",
                        },
                      }}
                    >
                      <IconDownload size={18} />
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            ))}
            <Button
              sx={{
                background: "red",
                color: "white",
                marginTop: "4px",
                width: "100%",
                ":hover": {
                  background: "red",
                },
              }}
              onClick={onClose}
            >
              Close preview
            </Button>
          </Box>
        )}
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false,
            },
            pdfVerticalScrollByDefault: true,
          }}
          documents={
            selectedDocument
              ? [
                  {
                    uri: `${CDN_URL}${selectedDocument.documentLink}${CDN_SIGN}`,
                    fileType: selectedDocument.fileType,
                  },
                ]
              : []
          }
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    </Box>
  );
};

export default DocumentPreview;
