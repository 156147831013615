import {
  AccountInfo,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import "@fontsource/open-sans";
import { PaletteColor, PaletteColorOptions } from "@mui/material";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./index.sass";
import reportWebVitals from "./reportWebVitals";
import { msalConfig } from "./utils/msalConfig";
import { nanoid } from "nanoid";
import { applicationinsights, reactPlugin } from "./utils/app-insights";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";

declare module "@mui/material/styles" {
  interface Palette {
    deloitteGreen: PaletteColor;
  }

  interface PaletteOptions {
    deloitteGreen: PaletteColorOptions;
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    const sessionId = sessionStorage.getItem("msalSessionId");
    console.log(`Login hit Session ID: ${sessionId}`);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      (event.payload as AccountInfo)
    ) {
      const account = (event.payload as any).account;
      localStorage.setItem("tokenResponse", JSON.stringify(account));
      msalInstance.setActiveAccount(account);
      const sessionId = nanoid();
      console.log(`Account: `, account);
      applicationinsights.setAuthenticatedUserContext(
        account.username,
        account.homeAccountId,
        true
      );
      sessionStorage.setItem("msalSessionId", sessionId);
      console.log(`Login hit Session ID: ${sessionId}`);
    }

    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      localStorage.setItem(
        "tokenResponse",
        JSON.stringify((event.payload as any).account)
      );
      const sessionId = sessionStorage.getItem("msalSessionId");
      console.log(`Login hit Session ID: ${sessionId}`);
    }

    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      applicationinsights.clearAuthenticatedUserContext();
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <h1>I believe something went wrong</h1>}
        appInsights={reactPlugin}
      >
        <App pca={msalInstance} />
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
