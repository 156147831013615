import React from "react";
import { CURRENT_VERSION, versionInfo } from "../../pages/popupMapper";
import {
  Backdrop,
  Box,
  Divider,
  Modal as MuiModal,
  Stack,
  Typography,
} from "@mui/material";
import { UcmpButton } from "ui-components-marketplace";
import { Checkbox, Color } from "@usitsdasdesign/dds-react";
import { BACKGROUND_IMAGE, LOGO_URL } from "../../utils/url";

import classes from './style.module.sass'

export default function AppUpdateInfoModal() {
  const [showPopup, setShowPopup] = React.useState(true);
  const [showUpdates, setShowUpdates] = React.useState(true);

  const popupContent = versionInfo[CURRENT_VERSION];
  const closePopup = () => {
    localStorage.setItem("appVersion", CURRENT_VERSION);
    localStorage.setItem("showUpdates", showUpdates.toString());
    setShowPopup(false);
  };

  const setLocalStorage = (value: any) => {
    setShowUpdates(!value.value);
  };

  React.useEffect(() => {
    if (
      localStorage.getItem("appVersion") === CURRENT_VERSION ||
      localStorage.getItem("showUpdates") === "false"
    ) {
      setShowPopup(false);
    }
  }, [CURRENT_VERSION]);
  return (
    <Backdrop open={showPopup}  sx={{ backgroundImage: `url(${BACKGROUND_IMAGE})`, zIndex: 999, backgroundSize: "100% auto" }}>
      <img
        src={LOGO_URL}
        alt="Deloitte AI & Data assets hub."
        height="400"
      />
      <MuiModal
        open={showPopup}
        onClose={closePopup}
        title={`What's new - v` + CURRENT_VERSION}
      >
        <Box
          sx={{
            backgroundColor: "white",
            height: "auto",
            width: "80%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 1,
          }}
          className={classes.popupWrap}
        >
          <Stack direction={"column"} className={classes.popupContent}>
            <Typography variant="h6" component="h2">
              {popupContent.header}
            </Typography>
            <Typography variant="body1" component="h3" mb={0.5}>
              {popupContent.subheader}
            </Typography>
            <Divider />
            <Box flexGrow={1} alignContent={"center"}>
              <ul>
                {popupContent.details.map((details, index) => (
                  <li key={index}>
                    <Typography variant="body2">{details}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
            <Stack direction={"row"} spacing={1} my={0.5}>
              <Checkbox
                label="Do not show again"
                color={Color.blue}
                ariaLabel="Do not show again"
                value={!showUpdates}
                onChangeHandler={setLocalStorage}
              />
              <Typography variant="body2">Do not show again</Typography>
            </Stack>
            <Divider />
            <Box
              flexDirection={"row"}
              width={1}
              display={"flex"}
              alignItems={"center"}
              alignSelf={"end"}
              justifyContent={"space-between"}
              pt={1}
            >
              <Typography variant="body2">{popupContent.footer}</Typography>
              <UcmpButton
                variant="contained"
                color="info"
                size="medium"
                onClick={closePopup}
              >
                Close
              </UcmpButton>
            </Box>
          </Stack>
        </Box>
      </MuiModal>
    </Backdrop>
  );
}
