import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { CURRENT_VERSION } from "../pages/popupMapper";
import { INSTRUMENTATION_KEY } from "./url";

const reactPlugin = new ReactPlugin();

const applicationinsights = new ApplicationInsights({
  config: {
    connectionString: INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableFetchTracking: true,
  },
});

applicationinsights.loadAppInsights();

applicationinsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags["env"] = "prod";
  env['ver'] = CURRENT_VERSION;
});

export { applicationinsights, reactPlugin }