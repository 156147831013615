export const CDN_URL = process.env.REACT_APP_CDN_URL + '/';

export const MSL_CLIENT_ID = process.env.REACT_APP_MSL_CLIENT_ID;
export const MSL_AUTHORITY = process.env.REACT_APP_MSL_AUTHORITY;
export const MSL_REDIRECT_URI = process.env.REACT_APP_MSL_REDIRECT_URI;

export const CDN_SIGN = process.env.REACT_APP_CDN_SIGN;

export const INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATION_KEY;

export const LOGO_URL = `${CDN_URL}logo.gif${CDN_SIGN}`;
export const BACKGROUND_IMAGE = `${CDN_URL}Background.gif${CDN_SIGN}`;