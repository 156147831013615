export enum TabDetailsEnum {
    AI_DATA_CHAIN_NO = 0,
    SDLC_NO = 1,
    ARCHETYPE_NO = 2,

    AI_DATA_CHAIN = 'ai-data',
    SDLC = 'sdlc',
    ARCHETYPE = 'archetype',

    AI_DATA_CHAIN_HOME_URL = '/',
    SDLC_HOME_URL = '/sdlc',
    ARCHETYPE_HOME_URL = '/archetype',
}

export const PATHS = {
    ADMIN: '/admin',
}

export const ADMIN_PATHS = {
    ADMIN: '/admin',
    ENQUIRY: '/admin/enquiry',
    ENQUIRY_DETAILS: '/admin/enquiry/:id',
    INTAKE: '/admin/intake',
    INTAKE_DETAILS: '/admin/intake/:id',
    INSIGHT: '/admin/insights'
}

export const OTHER_PAGE_URLS = ['search', 'faqs', 'intake', 'feedback', 'playbook', 'intake/new', 'intake/listing']