import axios from 'axios';

axios.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('tokenResponse')!)?.idToken || ''}`;
  config.headers['SessionId'] = sessionStorage.getItem('session_id') || null;
  return config;
});


axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if(error.response.status === 401 && process.env.NODE_ENV !== 'development') {
    alert('Session expired.');
    localStorage.removeItem('tokenResponse');
    window.location.href = '/logout';
  }

  return Promise.reject(error);
});

export default axios;
