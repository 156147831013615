import { createSlice } from "@reduxjs/toolkit"
 
interface InitialState {
    isOpen: boolean
}
 
const initialState: InitialState = {
    isOpen: false
}
 
const sideNavSlice = createSlice({
    name: 'ideNav',
    initialState,
    reducers: {
        toggleSideNav: (state) => {
            state.isOpen = !state.isOpen;
        },
        closeSideNav: (state) => {
            state.isOpen = false;
        },
    },
});
 
export const { toggleSideNav, closeSideNav } = sideNavSlice.actions;
 
export default sideNavSlice.reducer;