import { IconButton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import * as React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
};

type Props = {
  title?: string;
  children: React.ReactElement;
  open: boolean;
  onClose: () => void;
  keepMounted?: boolean;
};

export default function BasicModal({
  children,
  open,
  title,
  onClose,
  keepMounted,
}: Props) {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => onClose?.()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted={keepMounted}
      >
        <Box sx={style}>
          <Box
            sx={{
              backgroundColor: "white",
              py: 0.5,
              px: 1,
              borderBottom: "1px solid #e0e0e0",
              display: "flex",
              justifyContent: title ? "space-between" : "flex-end",
            }}
          >
            {title && (
              <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
                {title}
              </Typography>
            )}

            <IconButton onClick={() => onClose?.()} sx={{ py: 0, px: 1 }}>
              x
            </IconButton>
          </Box>
          <Box sx={{ p: 1, margin: "auto" }}>{children}</Box>
        </Box>
      </Modal>
    </div>
  );
}
