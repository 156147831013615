import React from "react";
import chatboticon from "../../assets/images/chatbot.svg";
import Chatbox from "../../pages/Chatbot/chatbox";
import Modal from "react-modal";

import classes from "./style.module.sass";
import { Typography } from "@mui/material";
import { ChatAssistant } from "../../pages/ChatAssitant/ChatAssistant";

export default function ChatBotModal() {
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChatClick = () => {
    setIsChatOpen(true);
  };

  const handleChatClose = () => {
    setIsChatOpen(false);
  };

  const expandModal = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <img
        src={chatboticon}
        alt="Deloitte AI & Data assets hub chatbot."
        className={classes["chatbot-popup-icon"]}
        onClick={handleChatClick}
        style={{
          cursor: "pointer",
          visibility: isChatOpen ? "hidden" : "inherit",
        }}
      />

      <Modal
        isOpen={isChatOpen}
        onRequestClose={handleChatClose}
        className={classes["new-chat-modal"]}
        contentLabel="Chat"
      >
        <ChatAssistant
          handleChatClose={handleChatClose}
          expandModal={expandModal}
        ></ChatAssistant>
      </Modal>
    </>
  );
}
