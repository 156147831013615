import uiScreenshot from "./../assets/images/popup-bg.png";

export interface VersionInfo {
  image: string;
  header: string;
  subheader: string;
  details: string[];
  footer: string;
}

export const CURRENT_VERSION = "2.0.1";

export const versionInfo: Record<string, VersionInfo> = {
  "1.0.2": {
    header: "What's new",
    subheader: "Release version 1.0.2 enhancements:",
    image: uiScreenshot,
    details: [
      "We've broadened our asset offerings by integrating OperateEdge into our platform, enhancing capabilities significantly.",
      "A new, comprehensive view feature has been introduced, enabling a simultaneous overview of all assets in a single glance.",
      "Implementation of Single Sign-On (SSO) to fortify security measures across the platform.",
      "Both the intake and enquiry forms now come with pre-filled user information, streamlining the process and improving accuracy.",
      "We've addressed multiple bugs and significantly improved the loading speed of the Home page for a smoother user experience.",
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at aidataassetssupportteam@deloitte.com",
  },
  "1.0.3": {
    header: "What's new",
    subheader: "Release version 1.0.3 enhancements:",
    image: uiScreenshot,
    details: [
      "Improved asset descriptions and documentation, with an updated list of clients utilizing the asset.",
      "Introduced two new comprehensive view features for a simultaneous overview of all assets at once.",
      "Enhanced functionality allows for multiple asset enquiries from a single form, including enquiry type.",
      "Implemented a 'download all' feature for acquiring all reference documents with a single click.",
      "Expanded search capabilities enable users to locate assets tailored to specific needs.",
      "Resolved multiple software bugs to improve user experience.",
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at aidataassetssupportteam@deloitte.com",
  },
  "1.0.4": {
    header: "What's new",
    subheader: "Release version 1.0.4 enhancements",
    image: uiScreenshot,
    details: [
      "Enhanced asset descriptions and documentations, with an updated client list for improved clarity and insight.",
      "Introduced grid and tile views to categorize assets by Software Delivery Lifecycle and Engagement Archetype.",
      "Expanded search capabilities now allow users to filter assets based on hyperscalers, delivery lifecycle, and archetypes.",
      "Enhanced platform functionality now supports multiple asset enquiries via a single form.",
      "Implemented a 'Download all' feature, allowing users to acquire all necessary reference documents with a single click.",
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at usaidataassetscore@deloitte.com.",
  },
  "1.0.5": {
    header: "What's new",
    subheader: "Release version 1.0.5 enhancements:",
    image: uiScreenshot,
    details: [
      "Introduced new administrative functionalities for real-time tracking of enquiries, allowing for more effective monitoring",
      "Enhanced enquiry features, users can now raise enquiries on behalf of others",
      "Our asset descriptions and documentation have been improved, complete with an updated client list for greater clarity and insight",
      "Expanded asset offerings to include new GCP GenAI assets, providing more robust solutions to meet diverse needs",
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at usaidataassetscore@deloitte.com",
  },
  "1.0.6": {
    header: "What's new",
    subheader: "Release version 1.0.6 enhancements:",
    image: uiScreenshot,
    details: [
      "The intake form has been enhanced to gather more comprehensive information about the idea and now permits users to add additional details, if required",
      "The user interface now includes a feedback form to gather user input on its design and functionality",
      "Implemented new administrative features for real-time tracking of intakes",
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at usaidataassetscore@deloitte.com",
  },
  "1.0.7": {
    header: "What's new",
    subheader: "Release version 1.0.7 enhancements:",
    image: uiScreenshot,
    details: [
      "Onboarded 9 new assets to the platform: Data extractor (Data Xray), Data 360, Pricing as a service, LLM Ops, Operation room optimizer, Data masking as a service (DMaS), Deloitte analytics objects (DAO), Synthetic data generator, Operate optimizer",
      "The AI & Data assets hub playbook is now available on the hub, providing users with comprehensive guidance to better understand and utilize the application",
      "The AI & Data assets hub chat assistant has been modernized to enhance your experience by providing versatile and efficient assistance, supporting general Q&A on asset availability, and offering recommendations for proposals",
      "Users can now direct asset inquiries to specific business units, facilitating the selection of the most appropriate asset",
      "The assets page now includes pricing information for assets with special pricing, providing clearer cost insights for users",
      "The application now complies with Deloitte's cookie standards, ensuring greater data transparency and security for users"
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at usaidataassetscore@deloitte.com",
  },
  "2.0.0": {
    header: "What's new",
    subheader: "Release version 2.0.0 enhancements:",
    image: uiScreenshot,
    details: [
      "The AI & Data assets hub UI has been enhanced to feature re-organized data value chains with consolidated list of assets based on their capabilities",
      "All-new intuitive playbook to help users navigate through the enhanced portal",
      "Updated reference documents and demo videos",
      "Enhanced enquiry form facilitating users to enquire based on capabilities and features",
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at usaidataassetscore@deloitte.com",
  },
  "2.0.1": {
    header: "What's new",
    subheader: "Release version 2.0.1 enhancements:",
    image: uiScreenshot,
    details: [
      "Integrated five new capabilities: Language translator, ABC framework, Data ingestor, Test case generator, and Data mesh framework",
      "Added download feature for Playbook and FAQs",
      "Enhanced Intake and Enquiry forms with more user-friendly features",
      "Improved Insights for real-time tracking of intakes and enquiries, now with a visualisation dashboard",
      "Uploaded demo videos showcasing the latest capabilities, updated documentation, and client list for better clarity and insight",
    ],
    footer:
      "For more information on the updates and new features, please reach out to us at usaidataassetscore@deloitte.com",
  }
};
