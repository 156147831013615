import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { UcmpButton } from "ui-components-marketplace";
import * as _ from "lodash";
import React from "react";
import { Color, Tag } from "@usitsdasdesign/dds-react";
import DocumentPreview from "../DocumentPreview";
import { IconCloudDownload, IconEye } from "@tabler/icons-react";
import { saveAs } from "file-saver";
import ImgMapper from "../../utils/document-img-mapper";
import { CDN_SIGN, CDN_URL } from "../../utils/url";
import JSZip from "jszip";
import { Docs } from "../../utils/types";
import "./ReferenceDocumentSection.sass";

type Props = {
  documents: Docs[];
  assetName: string;
  assetInfoLink?: string;
};

export default function ReferenceDocumentSection({
  documents = [],
  assetName,
  assetInfoLink,
}: Props) {
  const [selectedDocument, setSelectedDocument] = React.useState<Docs>();
  const [downloadAllLoading, setDownloadAllLoading] = React.useState(false);

  async function handleZip(
    files: { name: string; link: string }[],
    name: string
  ) {
    try {
      setDownloadAllLoading(true);
      const zip = new JSZip();

      // Add Images to the zip file
      for (let i = 0; i < files.length; i++) {
        const response = await fetch(files[i].link);
        const blob = await response.blob();
        zip.file(files[i].name, blob);
      }

      // Generate the zip file
      const zipData = await zip.generateAsync({
        type: "blob",
        streamFiles: true,
      });

      // Create a download link for the zip file
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(zipData);
      link.download = `${name}.zip`;
      link.click();
    } catch (e) {
    } finally {
      setDownloadAllLoading(false);
    }
  }

  const openPreview = (document: any) => {
    setSelectedDocument(document);
  };

  const closePreview = () => {
    setSelectedDocument(undefined);
  };

  const onChangeFileSelection = (document: any) => {
    setSelectedDocument(document);
  };

  const handleDownload = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    downloadUrl: string,
    documentName: string = "overview"
  ) => {
    event.preventDefault();
    saveAs(
      `${CDN_URL}${downloadUrl}${CDN_SIGN}`,
      `${assetName} - ${documentName}.${downloadUrl.split(".").pop()}`
    );
  };

  const downloadAll = () => {
    handleZip(
      documents
        .filter((el) => el.documentLink && el.heading)
        .map((el) => ({
          link: `${CDN_URL}${el.documentLink}${CDN_SIGN}`,
          name: `${el.heading}.${el.documentLink?.split(".").pop()}`,
        })),
      assetName
    );
  };

  const theme = useTheme();

  if (!documents && !assetInfoLink)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          color: theme.palette.grey[500],
        }}
      >
        <Typography variant={"body2"}>No documents available</Typography>
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          paddingY: 2,
          px: 2,
          height: "calc(100vh - 320px)",
          a: {
            color: "#26890d",
          },
        }}
      >
        {assetInfoLink && (
          <>
            <Typography variant={"body2"}>
              {" "}
              Discover more about <strong>{assetName}</strong> by following this{" "}
              <a href={assetInfoLink} target="_blank">
                link.
              </a>{" "}
            </Typography>
          </>
        )}
        {!assetInfoLink && (
          <>
            {!selectedDocument && (
              <>
                <Grid container spacing={1} rowSpacing={1}>
                  {documents.map((el: Docs, index: number) => (
                    <Grid item xs={3} md={3} lg={3} xl={2} display="flex">
                      <Card
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          border: 1,
                          borderColor: theme.palette.grey[200],
                          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
                          p: 0,
                          cursor: "pointer",
                          height: "90px",
                        }}
                        onClick={() => {
                          if (el.documentLink) openPreview(el);
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: 1,
                            py: 1,
                            px: 1.5,
                            pb: "8px !important",
                            background: !el.documentLink
                              ? theme.palette.grey[200]
                              : "white",
                          }}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                            width={"100%"}
                            height={"100%"}
                          >
                            <Box
                              display="flex"
                              justifyContent={"space-between"}
                            >
                              <Box display="flex" gap={1}>
                                <img
                                  src={ImgMapper[el.fileType]}
                                  alt="Deloitte"
                                  width={el.fileType === "xlsx" ? 40 : 35}
                                  height={el.fileType === "xlsx" ? 42 : 40}
                                  style={{
                                    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",

                                    marginTop:
                                      el.fileType === "xlsx"
                                        ? "-5px"
                                        : undefined,
                                    WebkitFilter:
                                      "drop-shadow(5px 5px 5px rgba(0,0,0,0.3))",
                                    filter:
                                      "drop-shadow(5px 5px 5px rgba(0,0,0,0.3))",
                                  }}
                                />
                                <Box>
                                  <Tag
                                    color={
                                      el.documentLink ? Color.green : Color.blue
                                    }
                                    tagValue={{
                                      title: el.documentLink
                                        ? "NEW"
                                        : "COMING SOON",
                                    }}
                                    customClass="tag-custom-class"
                                  />
                                </Box>
                              </Box>
                              {el.documentLink && (
                                <Box>
                                  <IconEye
                                    style={{ paddingLeft: 8 }}
                                    onClick={() => {
                                      openPreview(el);
                                    }}
                                    className="button-download-icon"
                                  />
                                  <IconCloudDownload
                                    style={{ paddingLeft: 8 }}
                                    onClick={(event: any) => {
                                      handleDownload(
                                        event,
                                        `${el.documentLink}`,
                                        el.heading
                                      );
                                      event.stopPropagation();
                                    }}
                                    className="button-download-icon"
                                  />
                                </Box>
                              )}
                            </Box>

                            <Typography
                              variant={"body2"}
                              fontWeight={600}
                              mt="auto"
                            >
                              {_.capitalize(el.heading)}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                {documents.length === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      color: theme.palette.grey[500],
                    }}
                  >
                    <Typography variant={"body2"}>
                      No documents available
                    </Typography>
                  </Box>
                )}
                {documents.length !== 0 && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <UcmpButton
                      onClick={downloadAll}
                      sx={{ borderRadius: 0, fontSize: 13 }}
                      variant="contained"
                      size="small"
                      disabled={
                        documents.filter((el) => el.documentLink).length === 0
                      }
                    >
                      {!downloadAllLoading && (
                        <>
                          <IconCloudDownload style={{ paddingRight: 8 }} />
                          Download all documents
                        </>
                      )}
                      {downloadAllLoading && (
                        <>
                          <CircularProgress size={24} />
                          <span style={{ paddingLeft: 8 }}>Downloading...</span>
                        </>
                      )}
                    </UcmpButton>
                  </Box>
                )}
              </>
            )}

            <DocumentPreview
              onClose={closePreview}
              documents={documents}
              selectedDocument={selectedDocument}
              handleDownload={handleDownload}
              onChangeSelection={onChangeFileSelection}
            />
          </>
        )}
      </Box>
    </>
  );
}
