import {
  UserProfile,
} from "@usitsdasdesign/dds-react";
import classNames from "classnames";
import _ from "lodash";
import { ReactComponent as DeloitteLogo } from "./../../assets/images/Deloitte-logo.svg";
// import "./styles.sass";
import "./navbar.sass";
import { nanoid } from "nanoid";
import { NavbarProps } from "../../utils/interface/NavBar";


const Navbar: React.FC<NavbarProps> = ({ ...props }: NavbarProps) => {
  return (
    <div role="menubar" className="ucmp-header">
      <div className={`ucmp-header__container ucmp-header__container_fluid`}>
        <div className="ucmp-header__main">
          <div role="img" aria-label="Deloitte" className="ucmp-header__logo">
            <div className="ucmp-logo">
              <DeloitteLogo />
            </div>
          </div>
          <span className="ucmp-header__project_name">{props.projectName}</span>
        </div>
        <div className="ucmp-header__left_wrap">
          <div className="ucmp-header__navigation">
            <div className="ucmp-inline-flex ucmp-max-width">
              {props.customNavigationMenu &&
                props.customNavigationMenu.map((el) => {
                  const buttonClassNames = classNames({
                    "ucmp-button": true,
                    "ucmp-button__lg": true,
                    "ucmp-button__dark": !el.isActive,
                    "ucmp-button__disabled": el.disabled,
                    "ucmp-button__has_dropdown":
                      el.dropdown && el.dropdown.length > 0,
                  });
                  return (
                    <button
                      role="application"
                      type="button"
                      className={buttonClassNames}
                      key={_.kebabCase(el.title)}
                    >
                      <div
                        className="ucmp-button__content"
                        onClick={el.onClick}
                      >
                        <span className="ucmp-button__text">{el.title}</span>
                      </div>
                      {el.dropdown && el.dropdown.length > 0 && (
                        <ul className={`ucmp-navbar__dropdown-menu ucmp-header-sdlc`}>
                          {el.dropdown.map((item) => {
                            const dropdownItemClassNames = classNames({
                              "ucmp-navbar__dropdown-submenu":
                                item.dropdown && item.dropdown.length > 0,
                              disabled: item.disabled,
                              active: item.isActive,
                            });
                            return (
                              <li className={dropdownItemClassNames} key={nanoid()}>
                                <span
                                  className="ucmp-navbar__dropdown-item"
                                  onClick={item.onClick}
                                >
                                  {item.title}
                                </span>
                                {item.dropdown && item.dropdown.length > 0 && (
                                  <ul className="ucmp-navbar__dropdown-menu ucmp-header-sdlc">
                                    {item.dropdown.map((subItem) => {
                                      const subDropdownItemClassNames =
                                        classNames({
                                          "ucmp-navbar__dropdown-submenu":
                                            subItem.dropdown &&
                                            subItem.dropdown.length > 0,
                                          disabled: subItem.disabled,
                                          active: subItem.isActive,
                                        });
                                      return (
                                        <li
                                          className={subDropdownItemClassNames}
                                          onClick={subItem.onClick}
                                          key={nanoid()}
                                        >
                                          <span className="ucmp-navbar__dropdown-item">
                                            {subItem.title}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="ucmp-header__right_wrap">
          {props.userProfile && <UserProfile {...props.userProfile} />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
