import { Outlet } from "react-router-dom";
import { AppUpdateModal, ChatBotModal } from "../components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Auth from "../utils/hoc/Auth";
import useRoot from "../utils/hooks/useRoot";

import "./Root.sass";
import "../components/card-flip-styles.sass";

const Root = () => {
  const { navbarProp, isAdminRoute } = useRoot();

  if (process.env.NODE_ENV === "development") {
    return (
      <>
        <Navbar {...navbarProp} />
        {!isAdminRoute && <ChatBotModal />}
        <AppUpdateModal />
        <Outlet />
        <Footer />
      </>
    );
  } else
    return (
      <>
        <Auth>
        <Navbar {...navbarProp} />
        {!isAdminRoute && <ChatBotModal />}
        <AppUpdateModal />
        <Outlet />
        <Footer />
        </Auth>
      </>
    );
};

export default Root;
