import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { Box, Typography, useTheme } from "@mui/material";
import classes from "./../LoginPage/styles.module.sass";
import "./../LoginPage/LoginPage";
import LoginBackground from "./../../assets/images/login.svg";
import { UcmpButton } from "ui-components-marketplace";
import { loginRequest } from "../../utils/msalConfig";

export const LogoutPage = () => {
  const { instance } = useMsal();
  const theme = useTheme();

  useEffect(() => {
    if (instance.getAllAccounts().length > 0) {
      instance.logoutRedirect();
    }
  });

  return (
    <>
      <Navbar projectName="AI & Data assets hub" />
      <Box
        className={classes.main}
        sx={{
          backgroundImage: `url(${LoginBackground})`,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          padding={4}
          color={"#fff"}
          height={1}
          borderRadius={2}
          mx={6}
          gap={theme.spacing(2)}
          sx={{
            backgroundColor: "#000",
            minWidth: 400,
            boxShadow: "inset 0 0 20px rgba(255, 255, 255, 0.25)",
          }}
        >
          <Typography variant="h4" fontWeight={300}>
            Logout
          </Typography>
          <Typography variant="h6">
            You've been logged out successfully.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Box>
              <UcmpButton
                color="info"
                sx={{ borderRadius: 0 }}
                onClick={() => {
                  instance.loginRedirect(loginRequest);
                }}
              >
                Login
              </UcmpButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
