import { createSlice } from "@reduxjs/toolkit";
import { NavigationMenuItem } from "@usitsdasdesign/dds-react/Components/Header/types";
import { update } from "lodash";
import { CategoryTabItem } from "../utils/interface/Category";


interface InitialState {
    valueChainHeaderNav: NavigationMenuItem[];
    sdlcHeaderNav: NavigationMenuItem[];
    archTypeHeaderNav: NavigationMenuItem[];
    tabItem: [];
}
const initialState: InitialState = {
    valueChainHeaderNav: [],
    sdlcHeaderNav: [],
    archTypeHeaderNav: [],
    tabItem: []
}

const headerNavSlice = createSlice({
    name: 'headerNav',
    initialState,
    reducers: {
        updateValueChainHeaderNav: (state, action) => {
            state.valueChainHeaderNav = action.payload;
        },
        updateSdlcHeaderNav: (state, action) => {
            state.sdlcHeaderNav = action.payload;
        },
        updateArchTypeHeaderNav: (state, action) => {
            state.archTypeHeaderNav = action.payload;
        },
        updateTabItem: (state, action) => {
            state.tabItem = action.payload;
        },
    }
});

export const { updateValueChainHeaderNav, updateSdlcHeaderNav, updateArchTypeHeaderNav, updateTabItem } = headerNavSlice.actions;
export default headerNavSlice.reducer;