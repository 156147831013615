import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducer";
import sideNavReducer from "./sidenavReducer";
import searchFilterReducer from "./searchFilterReducer";
import userReducer from "./userReducer";
import assetCardReducer from './valueChainCardReducer';
import headerNavReducer from "./headerNavReducer";
import serverConfigReducer from "./serverConfigReducer";

const store = configureStore({
  reducer: {
    root: RootReducer,
    sideNav: sideNavReducer,
    searchFilter: searchFilterReducer,
    user: userReducer,
    assetsCard: assetCardReducer,
    navHeader: headerNavReducer,
    serverConfig: serverConfigReducer,
  },
});

export type GlboalState = ReturnType<typeof store.getState>;
export default store;
