import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArchType, SdlcType, ValueChainType } from "../utils/interface/Category";
import { Category } from "../utils/types";

interface InitialState {
    valueChainCard: Category[];
    sdlcCard: Category[];
    archtypeCard: Category[];

}

const initialState: InitialState = {
    valueChainCard: [],
    sdlcCard: [],
    archtypeCard: []
}

const assetCardSlice = createSlice({
    name: 'assetCards',
    initialState,
    reducers: {
        updateValueChainCard: (state, action: PayloadAction<Category[]>) => {
            state.valueChainCard = action.payload;
        },
        updateSdlcCard: (state, action: PayloadAction<Category[]>) => {
            state.sdlcCard = action.payload;
        },
        updateArchTypeCard : (state, action: PayloadAction<Category[]>) => {
            state.archtypeCard = action.payload;
        }
    }
});

export const {updateValueChainCard, updateSdlcCard, updateArchTypeCard} = assetCardSlice.actions;
export default assetCardSlice.reducer;