import { PublicClientApplication } from "@azure/msal-browser";
import {
  // AuthenticatedTemplate,
  MsalProvider,
} from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import store from "./redux/store";
import router from "./utils/router";
import { theme } from "./utils/theme";
import { Helmet } from "react-helmet";

export const App = ({ pca }: { pca: PublicClientApplication }) => {
  const dataDomainScript = process.env.REACT_APP_DATA_DOMAIN_SCRIPT;
  return (
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Helmet>
            <script
              async
              src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
              type="text/javascript"
              charSet="UTF-8"
              data-domain-script={dataDomainScript}
            ></script>
            <script async type="text/javascript">
              {`
                function OptanonWrapper() { }
              `}
            </script>
          </Helmet>
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  );
};
