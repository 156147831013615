import { Box, Typography, useTheme } from "@mui/material";

import { InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { UcmpButton } from "ui-components-marketplace";
import Navbar from "../../components/Navbar";
import { loginRequest } from "../../utils/msalConfig";
import LoginBackground from "./../../assets/images/login.svg";
import classes from "./styles.module.sass";
import "./styles.sass";

const LoginPage = () => {
  const theme = useTheme();

  const { instance, inProgress } = useMsal();

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          if (
            inProgress == InteractionType.None &&
            !(instance.getAllAccounts().length > 0)
          ) {
            instance.loginRedirect(loginRequest);
          }
        } else {
          localStorage.setItem("tokenResponse", JSON.stringify(tokenResponse));
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <Navbar projectName="AI & Data assets hub" />
      <Box
        className={classes.main}
        sx={{
          backgroundImage: `url(${LoginBackground})`,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          padding={4}
          color={"#fff"}
          height={1}
          borderRadius={2}
          mx={6}
          gap={theme.spacing(2)}
          sx={{
            backgroundColor: "#000",
            minWidth: 400,
            boxShadow: "inset 0 0 20px rgba(255, 255, 255, 0.25)",
          }}
        >
          <Typography variant="h4" fontWeight={300}>
            Login
          </Typography>
          <Typography variant="h6">
            Welcome, please login to continue
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Box>
              <UcmpButton
                color="info"
                sx={{ borderRadius: 0 }}
                onClick={() => {
                  instance.loginRedirect(loginRequest);
                }}
              >
                Login
              </UcmpButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
