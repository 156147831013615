const initialState: any[] = [];

const TestReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "test/action": {
      return { ...state, testData: action.payload };
    }

    default:
      return state;
  }
};

export default TestReducer;
