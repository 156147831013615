import { Box } from "@mui/material";
import ReactPlayer from "react-player";
import { CDN_SIGN, CDN_URL } from "../../utils/url";

type Props = {
  title?: string;
  url: string;
};

const VideoPlayer = ({ url }: Props) => {
  const finalUrl = `${CDN_URL}${url}${CDN_SIGN}`;
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <ReactPlayer url={`${finalUrl}`} controls playing={true} />
    </Box>
  );
};

export default VideoPlayer;
