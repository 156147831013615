import { createSlice } from "@reduxjs/toolkit"
import { User } from "../utils/types";
 
interface InitialState {
    userDetails: User | null
}
 
const initialState: InitialState = {
    userDetails: null
}
 
const userSlice = createSlice({
    name: 'usser',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        clearUserDetails: (state) => {
            state.userDetails = null;
        },
    },
});
 
export const { setUserDetails, clearUserDetails } = userSlice.actions;
 
export default userSlice.reducer;