import { ThemeOptions, createTheme } from "@mui/material";

const themeOptions: ThemeOptions = {
  palette: {
    grey: {
      "50": "#f5f5f5",
      "100": "#e9e9e9",
      "200": "#d9d9d9",
      "300": "#c4c4c4",
      "400": "#9d9d9d",
      "500": "#7b7b7b",
      "600": "#555555",
      "700": "#434343",
      "800": "#262626",
      "900": "#000000",
    },
    primary: {
      // main: "#53565a",
      main: "#26890d",
    },
    secondary: {
      main: "#005587",
    },
    success: {
      main: "#26890d",
    },
    info: {
      main: "#007cb0",
    },
    warning: {
      main: "#ffcd00",
    },
    error: {
      main: "#da291c",
    },
    deloitteGreen: {
      main: "#86bc25",
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: "'Open Sans', Verdana, Helvetica, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCardActions: {
      styleOverrides: {
        spacing: ({ theme }) => theme.spacing(2),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        text: {
          textTransform: "none",
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
