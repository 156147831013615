import { createSlice } from "@reduxjs/toolkit"
 
interface InitialState {
    isOpen: boolean
}
 
const initialState: InitialState = {
    isOpen: true
}
 
const searchFilter = createSlice({
    name: 'searchFilterSidebar',
    initialState,
    reducers: {
        toogleFilter: (state) => {
            state.isOpen = !state.isOpen;
        },
        setFilterOpen: (state, action) => {
            state.isOpen = action.payload;
        }
    },
});
 
export const { toogleFilter, setFilterOpen } = searchFilter.actions;
 
export default searchFilter.reducer;